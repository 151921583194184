import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const RESUME_SUBSCRIPTION_MUTATION = gql`
  mutation resumeSubscription(
    $subscription_id: ID!
    $profile_id: ID!
  ) {
    resumeSubscription(
      input: {
        subscription_id: $subscription_id
        profile_id: $profile_id
      }
    ) {
      id
      stripe_id
    }
  }
`
export const useResumeSubscription = options => {
  const [resumeSubscription, { data, loading, error }] = useMutation(
    RESUME_SUBSCRIPTION_MUTATION,
    options,
  )

  return { resumeSubscription, data, loading, error }
}
