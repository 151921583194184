import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import {
  colors,
  misc,
  spacer,
  mixins,
} from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import Link from '@dqp/common/components/Link/Link'
import {
  SubjectTableRowProps,
  getTooltipItems,
  getSubscriptionData,
  getRenewalDate,
  getActionButton,
} from './SubjectTableRow'
import Tooltip from '#components/Tooltip/Tooltip'
import routes from '#globals/routes'

const styles = {
  container: css`
    background-color: ${colors.white};
    margin-bottom: 20px;
    border-radius: ${misc.borderRadius.md};
    box-shadow: ${misc.boxShadow.default};
  `,
  header: css`
    padding: 15px;
    border-bottom: 1px solid ${colors.greyLight};
  `,
}
const labelStyleProps = {
  size: `xSmall`,
  color: colors.grey,
  weight: `bold`,
}

const textStyleProps = {
  size: `medium`,
  color: colors.black,
  as: `span`,
  css: css`
    ${mixins.respondToMax(misc.viewport['b-vp'])} {
      font-size: 14px;
    }
  `,
}

function TabletAndMobileView({ subjects }) {
  return subjects.map(subject => (
    <div key={subject.id}>
      <Card {...subject} />
    </div>
  ))
}

const Card = props => {
  const profileRoute = routes.subjectDashboard.getPathFromParams({
    profileId: props.profile.id,
    subscriptionId: props.id,
  })
  return (
    <div css={styles.container}>
      <header
        css={styles.header}
        className='d-flex align-items-center justify-content-between'
      >
        <div>
          <Text {...labelStyleProps}>User</Text>
          <Text {...textStyleProps} weight='bold'>
            {props.profile.name}
          </Text>
        </div>
        {getTooltipItems(props) && (
          <Tooltip>{getTooltipItems(props)}</Tooltip>
        )}
      </header>
      <div className='container-md'>
        <div className='row' css={spacer.padBT20}>
          <div
            className='col-12 col-sm-4'
            css={css`
              ${mixins.respondToMax(misc.viewport['c-vp'])} {
                ${spacer.mrB20}
              }
            `}
          >
            <Text {...labelStyleProps}>Subject</Text>
            <Text {...textStyleProps}>
              <Link to={profileRoute}>{props.product.name}</Link>
            </Text>
          </div>

          <div className='col-6 col-sm-4'>
            <Text {...labelStyleProps}>Subscription</Text>
            <Text {...textStyleProps}>
              {getSubscriptionData(props)}
            </Text>
          </div>

          {getRenewalDate(props) && (
            <div className='col-6 col-sm-4'>
              <Text {...labelStyleProps}>Renewal Date</Text>
              <Text {...textStyleProps}>{getRenewalDate(props)}</Text>
            </div>
          )}

          {getActionButton(props) && (
            <div className='col-12' css={spacer.mrT20}>
              {getActionButton(props)}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

Card.propTypes = SubjectTableRowProps

TabletAndMobileView.propTypes = {
  subjects: PropTypes.array.isRequired,
}

export default TabletAndMobileView
