import { css } from '@emotion/core'
import {
  colors,
  misc,
  breakpoints,
  spacer,
  mixins,
} from '@dqp/common/styles/utilities'
import { rgba } from 'polished'

export default {
  container: css`
    background-color: ${colors.white};
    box-shadow: ${misc.boxShadow.default};
    border-radius: ${misc.borderRadius.md};
  `,

  header: ({ isFormVisible }) =>
    css`
      border-radius: ${misc.borderRadius.md};
      :hover {
        background-color: ${colors.greyLightest};
      }

      ${mixins.transition({ properties: 'background' })};
      ${isFormVisible &&
        css`
          border-bottom: 2px solid ${colors.greyLight};
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        `}
    `,
  btn: css`
    width: 100%;
    ${spacer.padLR20};
    ${spacer.padBT20};
  `,
  img: ({ isFormVisible }) => css`
    width: 40px;
    ${breakpoints({
      marginRight: [10, 20],
    })};
    transform: ${isFormVisible ? 'rotate(45deg)' : 'rotate(0)'};
    ${mixins.transition()}
  `,
  form: css`
    border-radius: 0 0 4px 4px;
    ${breakpoints({
      padding: [
        `20px`,
        `20px`,
        `50px 40px`,
        `70px 60px`,
        `70px 60px`,
      ],
    })}

    .row {
      margin-bottom: 50px;
      padding-bottom: 50px;
      border-bottom: 2px solid ${rgba(colors.greyLight, 0.5)};
      :last-child {
        margin-bottom: 0;
      }
      &:nth-last-of-type(-n + 2) {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
    ${mixins.transition()}
  `,

  numberInCircle: css`
    ${spacer.mrR10};
    ${spacer.mrB20};
  `,

  creditCard: ({ isSelected }) => css`
    cursor: pointer;
    border: 2px solid ${isSelected ? colors.purple : colors.black};

    :hover {
      background-color: ${colors.greyLightest};
    }
    box-shadow: ${misc.boxShadow.inputFieldWithBorder};
    ${mixins.transition()};
  `,
  checkbox: css`
    display: none;
  `,
}
