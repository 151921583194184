import { object, string, bool } from 'yup'

const paymentMethodIdSubSchema = (
  showSavedCards,
  isFreeSubscription,
  schema,
) => {
  if (isFreeSubscription) return schema
  if (showSavedCards) return schema.required('Please choose a card')
  return schema
}

const cardValidationSubSchema = (
  showSavedCards,
  isFreeSubscription,
  schema,
) => {
  if (isFreeSubscription) return schema
  if (showSavedCards) return schema
  return schema
    .oneOf([true], 'Please enter valid details')
    .required('Please enter valid details')
}

const validationSchema = object({
  subject: object().required('Please select a subject'),
  subscription: string().required(
    'Please choose a Subscription plan',
  ),

  isCardValid: bool().when(
    ['$showSavedCards', '$isFreeSubscription'],
    cardValidationSubSchema,
  ),

  payment_method_id: string().when(
    ['$showSavedCards', '$isFreeSubscription'],
    paymentMethodIdSubSchema,
  ),

  user: object().when(
    '$isAssigningToNewUser',
    (isAssigningToNewUser, schema) =>
      isAssigningToNewUser
        ? schema
        : schema.required('Please select a user'),
  ),

  userIcon: string().when(
    '$isAssigningToNewUser',
    (isAssigningToNewUser, schema) =>
      isAssigningToNewUser
        ? schema.required('Please choose an icon')
        : schema,
  ),

  searchUserName: string().when(
    '$isAssigningToNewUser',
    (isAssigningToNewUser, schema) =>
      isAssigningToNewUser
        ? schema.required('Please add a name')
        : schema,
  ),
})

export default validationSchema
