import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

export const CHANGE_SUBSCRIPTION_PROFILE_MUTATION = gql`
  mutation changeSubscriptionProfile(
    $subscription_id: ID!
    $profile_id: ID!
    $new_profile_id: ID!
  ) {
    changeSubscriptionProfile(
      input: {
        subscription_id: $subscription_id
        profile_id: $profile_id
        new_profile_id: $new_profile_id
      }
    ) {
      message
      status
    }
  }
`
export const useChangeSubscriptionProfile = options => {
  const [
    changeSubscriptionProfile,
    { data, loading, error },
  ] = useMutation(CHANGE_SUBSCRIPTION_PROFILE_MUTATION, options)

  return { changeSubscriptionProfile, data, loading, error }
}
