import React from 'react'
import PropTypes from 'prop-types'
import VisibleLargeScreens from '@dqp/common/components/VisibleLargeScreens/VisibleLargeScreens'
import VisibleMediumScreens from '@dqp/common/components/VisibleMediumScreens/VisibleMediumScreens'
import DesktopView from './DesktopView'
import TabletAndMobileView from './TabletAndMobileView'

function ManageSubjectsTable({ subjects }) {
  return (
    <>
      <div className='container-md'>
        <VisibleLargeScreens>
          <DesktopView subjects={subjects} />
        </VisibleLargeScreens>
        <VisibleMediumScreens>
          <TabletAndMobileView subjects={subjects} />
        </VisibleMediumScreens>
      </div>
    </>
  )
}

ManageSubjectsTable.propTypes = {
  subjects: PropTypes.array.isRequired,
}

export default ManageSubjectsTable
