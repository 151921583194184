import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { colors, misc } from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import ManageSubjectsTableRow from './SubjectTableRow'

const styles = {
  table: css`
    width: 100%;
    thead {
      th {
        padding: 5px 20px;
        font-size: 12px;
      }
    }
    tbody {
      box-shadow: ${misc.boxShadow.default};
      border-radius: 4px;
      tr {
        :nth-of-type(odd) {
          td {
            background-color: ${colors.greyLightest};
          }
        }
      }
    }
  `,
  tbodyWithSingleSubject: css`
    tr {
      td {
        :first-of-type {
          border-radius: 4px 0 0 4px;
        }
        :last-of-type {
          border-radius: 0 4px 4px 0;
        }
      }
    }
  `,
  tbodyWithMultipleSubjects: css`
    tr:first-of-type {
      td {
        :first-of-type {
          border-radius: 4px 0 0 0;
        }
        :last-of-type {
          border-radius: 0 4px 0 0;
        }
      }
    }
    tr:last-of-type {
      td {
        :first-of-type {
          border-radius: 0 0 0 4px;
        }
        :last-of-type {
          border-radius: 0 0 4px 0;
        }
      }
    }
  `,
}
function DesktopTable({ subjects }) {
  const tbodyStyles =
    subjects.length === 1
      ? styles.tbodyWithSingleSubject
      : styles.tbodyWithMultipleSubjects
  return (
    <table css={[styles.table, tbodyStyles]}>
      <thead>
        <tr>
          <Text
            as='th'
            weight='bold'
            size='small'
            color={colors.white}
          >
            SUBJECT
          </Text>
          <Text
            as='th'
            weight='bold'
            size='small'
            color={colors.white}
          >
            USER
          </Text>
          <Text
            as='th'
            weight='bold'
            size='small'
            color={colors.white}
          >
            SUBSCRIPTION
          </Text>
          <Text
            as='th'
            weight='bold'
            size='small'
            color={colors.white}
          >
            RENEWAL DATE
          </Text>
          <th> </th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {subjects.map(subject => (
          <ManageSubjectsTableRow key={subject.id} {...subject} />
        ))}
      </tbody>
    </table>
  )
}

DesktopTable.propTypes = {
  subjects: PropTypes.array.isRequired,
}

export default DesktopTable
