import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Title from '@dqp/common/components/Title/Title'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import Select from '@dqp/common/components/Select/Select'
import { object, string } from 'yup'
import { validateYupSchema, yupToFormErrors, useFormik } from 'formik'
import Text, { ErrorText } from '@dqp/common/components/Text/Text'
import TextField from '@dqp/common/components/TextField/TextField'
import { get } from 'lodash-es'
import ModalContainer from '#components/ModalContainer/ModalContainer'
import Stack from '#components/Stack/Stack'
import { useCreateProfile } from '#api/createProfile'
import UserIconSelect from '#components/UserIconSelect/UserIconSelect'
import Pill from '#components/Pill/Pill'
import parseValidationErrors from '#utils/parseValidationErrors'
import getErrorMessage from '#utils/getErrorMessage'

const schemaForNewUser = object({
  userIcon: string().required('Please choose an icon'),
  userName: string().required('Please add a name'),
})

const schemaForExistingUser = object({
  user: object().required('Please select a user'),
})

function ChangeProfileModal({
  isOpen,
  currentProfile,
  profiles,
  closeModal,
  profileIcons,
  onPrimaryButtonClick,
}) {
  const [errorMessage, setErrorMessage] = useState('')
  const { createProfile } = useCreateProfile()
  const [isAssigningToNewUser, setIsAssigningToNewUser] = useState(
    false,
  )
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    isSubmitting,
    dirty,
    isValid,
  } = useFormik({
    // eslint-disable-next-line
    validate: async formData => {
      try {
        await validateYupSchema(
          formData,
          isAssigningToNewUser
            ? schemaForNewUser
            : schemaForExistingUser,
        )
      } catch (error) {
        return yupToFormErrors(error)
      }
    },

    initialValues: {
      user: currentProfile,
      userIcon: '',
      userName: '',
    },
    onSubmit: async (formData, actions) => {
      try {
        let profileId
        if (isAssigningToNewUser) {
          const { data } = await createProfile({
            variables: {
              icon_id: formData.userIcon,
              name: formData.userName,
            },
          })
          const { id } = get(data, 'createProfile', {})
          profileId = id
        } else {
          profileId = formData.user.value
        }
        await onPrimaryButtonClick(profileId)
      } catch (error) {
        const validationErrors = parseValidationErrors(error)
        const message = getErrorMessage(error)
        if (validationErrors) actions.setErrors(validationErrors)
        else if (message) setErrorMessage(message)
      }
    },
  })

  useEffect(() => {
    if (isAssigningToNewUser) {
      setFieldValue('userIcon', profileIcons[0].id)
    }
  }, [isAssigningToNewUser, profileIcons, setFieldValue])

  return (
    <ModalContainer isOpen={isOpen} closeModal={closeModal}>
      <form onSubmit={handleSubmit}>
        <Stack className='d-flex flex-column text-left' spacing={30}>
          <Title color={colors.black} size='xLarge'>
            Change User
          </Title>
          <div>
            <>
              {isAssigningToNewUser ? (
                <>
                  <TextField
                    label='Assign to a new user'
                    placeholder='eg. John'
                    name='userName'
                    onChange={handleChange}
                    onBlur={() => setFieldTouched('userName', true)}
                    value={values.userName}
                    error={touched.userName && errors.userName}
                    autoComplete='off'
                    css={spacer.mrB30}
                  />
                  <div
                    className='d-flex flex-column'
                    css={spacer.mrB30}
                  >
                    <Text color={colors.black} css={spacer.mrB10}>
                      Choose user&apos;s icon
                    </Text>
                    <div
                      className='d-flex flex-wrap'
                      style={{ marginBottom: -10, marginRight: -20 }}
                    >
                      {profileIcons.map(({ icon, id }) => (
                        <UserIconSelect
                          key={id}
                          iconUrl={icon}
                          value={id}
                          name='userIcon'
                          onChange={handleChange}
                          onBlur={() =>
                            setFieldTouched('userIcon', true)
                          }
                          css={[spacer.mrR20, spacer.mrB10]}
                          checked={values.userIcon === id}
                        />
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <Select
                  label='Assign to an existing user'
                  options={profiles.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  name='user'
                  color={colors.black}
                  css={spacer.mrB30}
                  value={values.user}
                  error={touched.user && errors.user}
                  onChange={data => setFieldValue('user', data)}
                  onBlur={() => setFieldTouched('user', true)}
                />
              )}
              <div
                className='d-flex justify-content-center'
                css={spacer.mrB30}
              >
                <Pill text='OR' type='muted' />
              </div>
              <Button
                type='button'
                underlineColor={colors.black}
                onClick={() => setIsAssigningToNewUser(s => !s)}
              >
                {isAssigningToNewUser
                  ? 'Assign to existing user'
                  : 'Assign to new user'}
              </Button>
            </>
          </div>
          {errorMessage && (
            <ErrorText css={spacer.mrBT20}>{errorMessage}</ErrorText>
          )}
          <div className='d-flex align-items-center'>
            <Button
              type='submit'
              size='small'
              variant='blue'
              css={spacer.mrR20}
              isLoading={isSubmitting}
              disabled={!isValid || !dirty}
            >
              SAVE
            </Button>
            <Button
              type='button'
              onClick={closeModal}
              underlineColor={colors.black}
            >
              Cancel
            </Button>
          </div>
        </Stack>
      </form>
    </ModalContainer>
  )
}

ChangeProfileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentProfile: PropTypes.object.isRequired,
  profiles: PropTypes.array.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired,
  profileIcons: PropTypes.array.isRequired,
}

export default ChangeProfileModal
