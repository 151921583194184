import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { colors, mixins } from '@dqp/common/styles/utilities'

const styles = {
  label: css`
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  `,
  imgContainer: css`
    position: relative;
    padding: 3px;
    &::after {
      content: '';
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 110%;
      height: 110%;
      border: 2px solid transparent;
      ${mixins.transition()}
    }
  `,
  input: css`
    position: absolute;
    width: 0;
    height: 0;
    border: none;
    outline: none;
    visibility: hidden;
    &::placeholder {
      color: ${colors.greyMedium};
    }
    &:checked ~ .img-ctr {
      &::after {
        border-color: ${colors.black};
      }
    }
  `,
  img: css`
    width: 40px;
    height: 40px;
  `,
}

// TODO: refactor during integration

function UserIconSelect({
  type,
  checked,
  iconUrl,
  className,
  name,
  value,
  onChange,
  ...rest
}) {
  return (
    <label css={styles.label} className={className}>
      <input
        type={type}
        name={name}
        css={styles.input}
        value={value}
        onChange={onChange}
        checked={checked}
        {...rest}
      />
      <div css={styles.imgContainer} className='img-ctr'>
        <img src={iconUrl} alt='user-icon' css={styles.img} />
      </div>
    </label>
  )
}

UserIconSelect.propTypes = {
  type: PropTypes.oneOf(['radio', 'checkbox']),
  checked: PropTypes.bool.isRequired,
  iconUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

UserIconSelect.defaultProps = {
  type: 'radio',
  className: '',
  name: '',
  value: '',
}
export default UserIconSelect
