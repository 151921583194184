import React from 'react'
import PropTypes from 'prop-types'
import Title from '@dqp/common/components/Title/Title'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import Text from '@dqp/common/components/Text/Text'
import ModalContainer from '#components/ModalContainer/ModalContainer'
import Stack from '#components/Stack/Stack'

function CancelSubscriptionModal({
  isOpen,
  closeModal,
  userName,
  subjectName,
  renewalDate,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  isLoading,
}) {
  return (
    <ModalContainer isOpen={isOpen} closeModal={closeModal}>
      <Stack className='d-flex flex-column text-left' spacing={30}>
        <Title color={colors.black} size='xLarge'>
          Are you sure you want to cancel your subscription for{' '}
          {userName}
          ’s {subjectName}?
        </Title>

        <Text color={colors.charcoal}>
          You will no longer have access to this subject from{' '}
          {renewalDate}.
        </Text>

        <div className='d-flex align-items-center'>
          <Button
            size='small'
            variant='blue'
            css={spacer.mrR20}
            onClick={onPrimaryButtonClick}
            isLoading={isLoading}
          >
            CANCEL SUBSCRIPTION
          </Button>
          <Button
            onClick={onSecondaryButtonClick}
            underlineColor={colors.black}
          >
            Close
          </Button>
        </div>
      </Stack>
    </ModalContainer>
  )
}

CancelSubscriptionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired,
  onSecondaryButtonClick: PropTypes.func.isRequired,
  subjectName: PropTypes.string.isRequired,
  renewalDate: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default CancelSubscriptionModal
