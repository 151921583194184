import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const GET_PROFILE_ICONS_QUERY = gql`
  query getProfileIcons {
    getProfileIcons {
      id
      icon
    }
  }
`
export const useGetProfileIcons = () => {
  const { data: profileIcons, loading, error } = useQuery(
    GET_PROFILE_ICONS_QUERY,
  )
  return { profileIcons, loading, error }
}
