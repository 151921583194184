import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Button from '@dqp/common/components/Button/Button'
import { useRetrySubscriptionPayment } from '#api/retrySubscriptionPayment'
import { useSuccessOrErrorModalContext } from '#context/SuccessOrErrorModalContext'
import Stripe3DSModal from '#components/Modals/Stripe3DSModal'
import getErrorMessage from '#utils/getErrorMessage'
import getStripeNextActionUrl from '#utils/getStripeNextActionUrl'
import refetchUser from '#utils/refetchUser'
import {
  useNotificationContext,
  notificationActionTypes,
  notificationTypes,
} from '#context/NotificationsContext'

const RetryPayment = ({ subscription }) => {
  const {
    showErrorModal,
    showSuccessModal,
  } = useSuccessOrErrorModalContext()
  const [stripeSrc, setStripeSrc] = useState()
  const {
    retrySubscriptionPayment,
    loading,
  } = useRetrySubscriptionPayment()
  const { notificationsDispatch } = useNotificationContext()
  const showPaymentInformationNotification = useCallback(
    () =>
      notificationsDispatch({
        type: notificationActionTypes.SHOW_NOTIFICATION,
        payload: notificationTypes.PAYMENT_INFORMATION,
      }),
    [notificationsDispatch],
  )
  const handleClick = async () => {
    try {
      const { data } = await retrySubscriptionPayment({
        variables: {
          subscription_id: subscription.id,
        },
      })

      const stripeNextActionUrl = getStripeNextActionUrl(
        data.retrySubscriptionPayment,
      )

      if (stripeNextActionUrl) {
        setStripeSrc(stripeNextActionUrl)
      } else {
        showSuccessModal('Payment Succeeded')
        showPaymentInformationNotification()
      }
    } catch (error) {
      const message = getErrorMessage(error)
      if (message) showErrorModal(message)
    }

    refetchUser()
  }
  return (
    <>
      <Button
        variant='blue'
        size='small'
        onClick={handleClick}
        isLoading={loading}
      >
        Retry
      </Button>
      {stripeSrc && (
        <Stripe3DSModal
          iframeSrc={stripeSrc}
          onSuccess={async message => {
            await refetchUser()
            showSuccessModal(message)
            setStripeSrc('')
            showPaymentInformationNotification()
          }}
          onError={message => {
            showErrorModal(message)
            setStripeSrc('')
          }}
        />
      )}
    </>
  )
}

RetryPayment.propTypes = {
  subscription: PropTypes.object.isRequired,
}

export default RetryPayment
