import { colors } from '@dqp/common/styles/utilities'
import { css } from '@emotion/core'

export default {
  text: css`
    padding: 8px 20px;
    white-space: nowrap;
    text-align: left;
    cursor: pointer;
    :last-child {
      margin-bottom: 0;
    }
    :hover {
      background-color: ${colors.greyLightest};
    }
  `,
}
