import React from 'react'
import { css } from '@emotion/core'
import { triangle } from 'polished'
import Dropdown, {
  DropdownContent,
  DropdownTrigger,
} from 'react-simple-dropdown'
import PropTypes from 'prop-types'
import { misc, colors } from '@dqp/common/styles/utilities'
import { Kebab } from '@dqp/common/components/icons'
import 'rc-tooltip/assets/bootstrap_white.css'

const styles = {
  container: css`
    position: relative;
    .dropdown {
      display: inline-block;
      user-select: none;
    }
    .dropdown__trigger {
      display: block;
      cursor: pointer;
      padding: 5px;
      border-radius: ${misc.borderRadius.md};
      :hover {
        background-color: ${colors.greyLight};
      }
    }
    .dropdown__content {
      display: none;
      position: absolute;
      right: 0;
      top: 150%;
      z-index: ${misc.dropdownZindex};
    }

    .dropdown--active .dropdown__content {
      display: block;
    }
  `,

  content: css`
    background-color: ${colors.white};
    padding: 5px 0px;
    opacity: 1;
    box-shadow: ${misc.boxShadow.default};
    border-radius: ${misc.borderRadius.md};
  `,
  notch: css`
    ${triangle({
      pointingDirection: `top`,
      width: `10px`,
      height: `8px`,
      foregroundColor: colors.white,
    })};
    position: absolute;
    right: 10px;
    transform: translateY(-100%);
  `,
}

function Tooltip({ label: Label, children }) {
  return (
    <div css={styles.container}>
      <Dropdown>
        <DropdownTrigger>
          <Label css={styles.label} />
        </DropdownTrigger>
        <DropdownContent>
          <div className='relative'>
            <div css={styles.notch} />
          </div>

          <div css={styles.content}>{children}</div>
        </DropdownContent>
      </Dropdown>
    </div>
  )
}

Tooltip.defaultProps = {
  label: Kebab,
}

Tooltip.propTypes = {
  label: PropTypes.any,
  children: PropTypes.any.isRequired,
}

export default Tooltip
