import React from 'react'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import Text from '@dqp/common/components/Text/Text'
import { spacer, colors } from '@dqp/common/styles/utilities'
import Link from '@dqp/common/components/Link/Link'
import CancelSubscriptionTooltipItem from './TooltipItems/CancelSubscriptionTooltipItem'
import RestartSubscription from './Buttons/RestartSubscription'
import UpgradeSubscription from './Buttons/UpgradeSubscription'
import RetryPayment from './Buttons/RetryPayment'
import ResetSubscriptionTooltipItem from './TooltipItems/ResetSubscriptionTooltipItem'
import UpdateProfileTooltipItem from './TooltipItems/UpdateProfileTooltipItem'
import ChangeProfileTooltipItem from './TooltipItems/ChangeProfileTooltipItem'
import tooltipItemStyles from './TooltipItems/styles'
import Pill from '#components/Pill/Pill'
import Tooltip from '#components/Tooltip/Tooltip'
import { subscriptionTypes, stripeStatus } from '#globals/constants'
import dateToDDMMYYYY from '#utils/dateToDDMMYYYY'
import routes from '#globals/routes'

const styles = {
  img: css`
    vertical-align: middle;
    ${spacer.mrR10};
    width: 30px;
  `,
  td: css`
    padding: 15px 20px;
    background-color: white;
    border-radius: 0px;
  `,
  tr: css`
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 5px;
    border-radius: 4px;
  `,
}

export const getSubscriptionData = subscription => {
  const { type, stripe_status, is_active, ends_at } = subscription

  if (
    is_active === false &&
    [
      stripeStatus.past_due,
      stripeStatus.incomplete,
      stripeStatus.unpaid,
    ].includes(stripe_status)
  ) {
    return <Pill text='PENDING' type='danger' />
  }
  if (
    is_active === false &&
    stripe_status === stripeStatus.canceled
  ) {
    return <Pill text='EXPIRED' type='danger' />
  }

  // grace period
  if (ends_at != null && stripe_status === stripeStatus.active) {
    return <Pill text='CANCELLED' type='warning' />
  }

  switch (type) {
    case subscriptionTypes.YEARLY:
      return <span>&pound;99 Yearly</span>
    case subscriptionTypes.MONTHLY:
      return <span>&pound;15 Monthly</span>
    case subscriptionTypes.FREE:
      return <Pill text='FREE TRIAL' type='info' />
    default:
      return ``
  }
}

export const getRenewalDate = ({
  type,
  ends_at,
  current_period_end,
  stripe_status,
  is_active,
}) => {
  // subscription is in grace period
  if (ends_at != null && stripe_status === stripeStatus.active) {
    return `Access untill ${dateToDDMMYYYY(ends_at)}`
  }
  if (
    is_active === false &&
    stripe_status === stripeStatus.canceled
  ) {
    return <span>&ndash;</span>
  }
  if (ends_at == null) {
    switch (type) {
      case subscriptionTypes.MONTHLY:
      case subscriptionTypes.YEARLY:
        return `Renews on ${dateToDDMMYYYY(current_period_end)}`
      case subscriptionTypes.FREE:
      default:
        return <span>&ndash;</span>
    }
  }
  return ``
}

export const getTooltipItems = subscription => {
  const { type, stripe_status, is_active, ends_at } = subscription

  const resetItem = (
    <ResetSubscriptionTooltipItem
      css={tooltipItemStyles.text}
      subscription={subscription}
      label={
        <Text size='small' color={colors.black} weight='bold'>
          Reset Subject
        </Text>
      }
    />
  )
  const updateItem = (
    <UpdateProfileTooltipItem subscription={subscription} />
  )
  const changeProfileItem = (
    <ChangeProfileTooltipItem subscription={subscription} />
  )
  const cancelSubscriptionItem = (
    <CancelSubscriptionTooltipItem subscription={subscription} />
  )

  if (
    is_active === false &&
    stripe_status === stripeStatus.canceled
  ) {
    return <>{updateItem}</>
  }

  if (ends_at != null && stripe_status === stripeStatus.active) {
    return (
      <>
        {resetItem}
        {updateItem}
        {changeProfileItem}
      </>
    )
  }

  switch (type) {
    case subscriptionTypes.MONTHLY:
    case subscriptionTypes.YEARLY:
      return (
        <>
          {is_active ? resetItem : null}
          {updateItem}
          {changeProfileItem}
          {cancelSubscriptionItem}
        </>
      )

    case subscriptionTypes.FREE:
      return (
        <>
          {resetItem}
          {updateItem}
          {changeProfileItem}
        </>
      )
    default:
      return null
  }
}

export const getActionButton = subscription => {
  const { type, stripe_status, is_active, ends_at } = subscription
  if (
    is_active === false &&
    [
      stripeStatus.past_due,
      stripeStatus.incomplete,
      stripeStatus.unpaid,
    ].includes(stripe_status)
  ) {
    return <RetryPayment subscription={subscription} />
  }

  if (
    is_active === false &&
    stripe_status === stripeStatus.canceled
  ) {
    return null
  }

  if (ends_at != null && stripe_status === stripeStatus.active) {
    return <RestartSubscription subscription={subscription} />
  }

  switch (type) {
    case subscriptionTypes.FREE:
    case subscriptionTypes.MONTHLY:
      return <UpgradeSubscription subscription={subscription} />
    case subscriptionTypes.YEARLY:
    default:
      return null
  }
}

function ManageSubjectsTableRow(props) {
  const profileRoute = routes.subjectDashboard.getPathFromParams({
    profileId: props.profile.id,
    subscriptionId: props.id,
  })
  return (
    <tr>
      <Text as='td' size='large' color={colors.black} css={styles.td}>
        <Link to={profileRoute}>{props.product.name}</Link>
      </Text>
      <Text as='td' size='medium' color={colors.grey} css={styles.td}>
        {props.profile.name}
      </Text>

      <Text as='td' size='medium' color={colors.grey} css={styles.td}>
        {getSubscriptionData(props)}
      </Text>
      <Text as='td' size='medium' color={colors.grey} css={styles.td}>
        {getRenewalDate(props)}
      </Text>
      <td css={styles.td} className='text-right'>
        {getActionButton(props)}
      </td>
      <td css={styles.td} className='text-right'>
        {getTooltipItems(props) && (
          <Tooltip>{getTooltipItems(props)}</Tooltip>
        )}
      </td>
    </tr>
  )
}

export const SubjectTableRowProps = {
  id: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
}
ManageSubjectsTableRow.propTypes = SubjectTableRowProps

export default ManageSubjectsTableRow
