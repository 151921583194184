import React from 'react'
import PropTypes from 'prop-types'
import Text from '@dqp/common/components/Text/Text'
import { colors } from '@dqp/common/styles/utilities'
import { useMutation } from '@apollo/react-hooks'
import styles from './styles'
import useModal from '#utils/useModal'
import dateToDDMMYYYY from '#utils/dateToDDMMYYYY'
import { UPDATE_PROFILE_MUTAION } from '#api/updateProfile'
import UpdateUserModal from '#components/Modals/UpdateUserModal'
import { GET_USER_QUERY } from '#api/getUser'
import { useGetProfileIcons } from '#api/getProfileIcons'
import { useSuccessOrErrorModalContext } from '#context/SuccessOrErrorModalContext'
import getErrorMessage from '#utils/getErrorMessage'

const UpdateProfileTooltipItem = ({ subscription }) => {
  const { isOpen, openModal, closeModal } = useModal()
  const {
    showSuccessModal,
    showErrorModal,
  } = useSuccessOrErrorModalContext()
  const { profileIcons } = useGetProfileIcons()
  const [updateProfile, { loading }] = useMutation(
    UPDATE_PROFILE_MUTAION,
    {
      onCompleted: response => {
        if (response.updateProfile)
          showSuccessModal('Profile updated')
        closeModal()
      },
      onError: error => {
        const message = getErrorMessage(error)
        if (message) showErrorModal(message)
        closeModal()
      },
      refetchQueries: [
        {
          query: GET_USER_QUERY,
        },
      ],
      awaitRefetchQueries: true,
    },
  )
  return (
    <>
      <Text
        size='small'
        color={colors.black}
        weight='bold'
        onClick={openModal}
        css={styles.text}
      >
        Update User
      </Text>
      {isOpen && (
        <UpdateUserModal
          profileIcons={profileIcons.getProfileIcons}
          isOpen={isOpen}
          closeModal={closeModal}
          onSecondaryButtonClick={closeModal}
          subjectName={subscription.product.name}
          userName={subscription.profile.name}
          userIcon={subscription.profile.icon.id}
          renewalDate={dateToDDMMYYYY(
            subscription.current_period_end,
          )}
          isLoading={loading}
          onPrimaryButtonClick={({ iconId, name }) => {
            updateProfile({
              variables: {
                id: subscription.profile.id,
                icon_id: iconId,
                name,
              },
            })
          }}
        />
      )}
    </>
  )
}

UpdateProfileTooltipItem.propTypes = {
  subscription: PropTypes.object.isRequired,
}

export default UpdateProfileTooltipItem
