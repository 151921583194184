import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'

export const UPDATE_PROFILE_MUTAION = gql`
  mutation updateProfile($id: ID!, $icon_id: ID, $name: String) {
    updateProfile(
      input: { id: $id, icon_id: $icon_id, name: $name }
    ) {
      id
      name
    }
  }
`
export const useUpdateProfile = () => {
  const [updateProfile, { data, loading, error }] = useMutation(
    UPDATE_PROFILE_MUTAION,
  )
  return { updateProfile, data, loading, error }
}
