import React from 'react'
import { css, Global } from '@emotion/core'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import { colors, spacer } from '@dqp/common/styles/utilities'
import { noop } from 'lodash-es'
import ModalContainer from '#components/ModalContainer/ModalContainer'

const Overlay = () => {
  return (
    <ModalContainer
      isOpen
      closeModal={noop}
      crossBtnStyles={css`
        display: none;
      `}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <Global
        styles={css`
          .ReactModal__Overlay {
            background-color: rgba(0, 0, 0, 0.3) !important;
          }
        `}
      />
      <div className='text-center'>
        <Title color={colors.black} size='large' css={spacer.mrB10}>
          Please wait while we process your request.
        </Title>
        <Text color={colors.black} size='medium'>
          This window will close automatically.
        </Text>
      </div>
    </ModalContainer>
  )
}

export default Overlay
