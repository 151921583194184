import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { spacer } from '@dqp/common/styles/utilities'
import { get } from 'lodash-es'
import { navigate } from '@reach/router'
import ManageSubjectsTable from './ManageSubjectTable/ManageSubjectTable'
import AddSubjectForm from './AddSubjectForm/AddSubjectForm'
import Header from './Header'
import { useGetProducts } from '#api/getProducts'
import Loader from '#components/Loader/Loader'
import Error from '#components/Error/Error'
import { useGetProfileIcons } from '#api/getProfileIcons'
import { useGetProfiles } from '#api/getProfiles'
import { useGetUser } from '#api/getUser'
import { useThemeColor } from '#context/ThemeColorContext'
import routes from '#globals/routes'

const ManageSubjectContainer = ({ location }) => {
  const { setThemeColor } = useThemeColor()
  const {
    user,
    loading: userLoading,
    error: userError,
  } = useGetUser()

  const {
    profiles,
    loading: profilesLoading,
    error: profilesError,
  } = useGetProfiles()

  const { products, loading, error } = useGetProducts()

  const {
    profileIcons,
    loading: iconsLoading,
    error: iconsError,
  } = useGetProfileIcons()
  const shouldOpenForm = get(location, 'state.shouldOpenForm', false)
  const [isFormVisible, setIsFormVisible] = useState(false)

  useEffect(() => {
    if (shouldOpenForm) {
      setIsFormVisible(true)
      navigate(routes.app.to, {
        replace: true,
      })
    }
  }, [shouldOpenForm])
  useEffect(() => {
    const subscriptionsCount = get(
      user,
      'getUser.subscriptions.length',
    )
    if (subscriptionsCount === 0) {
      setIsFormVisible(true)
    }
  }, [user])

  useEffect(() => {
    setThemeColor(null)
  }, [setThemeColor])

  if (loading || iconsLoading || profilesLoading || userLoading) {
    return <Loader />
  }

  if (error || iconsError || profilesError || userError) {
    return <Error />
  }

  return (
    <>
      <Header />
      <AddSubjectForm
        profileIcons={profileIcons.getProfileIcons}
        existingProfiles={profiles.getProfiles}
        products={products.getProducts.data}
        isFormVisible={isFormVisible}
        setIsFormVisible={setIsFormVisible}
      />
      <div css={spacer.mrB40} />
      {user.getUser.subscriptions.length > 0 && !isFormVisible && (
        <ManageSubjectsTable subjects={user.getUser.subscriptions} />
      )}
    </>
  )
}

ManageSubjectContainer.propTypes = {
  location: PropTypes.object.isRequired,
}

export default ManageSubjectContainer
