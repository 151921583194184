import { apolloClient } from '#lib/Apollo'
import { GET_PROFILE_QUERY } from '#api/getProfile'

export default async profileId => {
  apolloClient.query({
    query: GET_PROFILE_QUERY,
    variables: { id: profileId },
    fetchPolicy: 'network-only',
  })
}
