import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { GET_PROFILES_QUERY } from './getProfiles'

const CREATE_PROFILE_MUTATION = gql`
  mutation createProfile($icon_id: ID!, $name: String!) {
    createProfile(input: { icon_id: $icon_id, name: $name }) {
      id
      name
      icon {
        id
        icon
      }
    }
  }
`
export const useCreateProfile = () => {
  const [createProfile, { data, loading, error }] = useMutation(
    CREATE_PROFILE_MUTATION,
    {
      refetchQueries: [{ query: GET_PROFILES_QUERY }],
    },
  )

  return { createProfile, data, loading, error }
}
