/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormik, yupToFormErrors } from 'formik'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import Text, { ErrorText } from '@dqp/common/components/Text/Text'
import Select from '@dqp/common/components/Select/Select'
import TextField from '@dqp/common/components/TextField/TextField'
import Checkbox from '@dqp/common/components/Checkbox/Checkbox'
import { find, get } from 'lodash-es'
import Link from '@dqp/common/components/Link/Link'
import validationSchema from './validationSchema'
import styles from './styles'
import plus from '#images/plus.svg'
import NumberInCircle from '#components/NumberInCircle/NumberInCircle'
import UserIconSelect from '#components/UserIconSelect/UserIconSelect'
import OptionBar from '#components/OptionBar/OptionBar'
import Pill from '#components/Pill/Pill'
import { useCreateProfile } from '#api/createProfile'
import { useCreateSubscription } from '#api/createSubscription'
import {
  subscriptionTypes,
  pricingTexts,
  pricingBandsStyles,
} from '#globals/constants'
import CreditCard from '#containers/MyAccount/CreditCard/CreditCard'
import Stack from '#components/Stack/Stack'
import useCreateNewPaymentMethod from '#utils/useCreatePaymentMethod'
import StripeCard from '#components/StripeCard/StripeCard'
import Stripe3DSModal from '#components/Modals/Stripe3DSModal'
import { useSuccessOrErrorModalContext } from '#context/SuccessOrErrorModalContext'
import getErrorMessage from '#utils/getErrorMessage'
import getStripeNextActionUrl from '#utils/getStripeNextActionUrl'
import Overlay from '#components/Modals/Overlay'
import refetchUser from '#utils/refetchUser'
import refetchProfile from '#utils/refetchProfile'
import Loader from '#components/Loader/Loader'
import useCards from '#utils/useCards'
import {
  useNotificationContext,
  notificationActionTypes,
  notificationTypes,
} from '#context/NotificationsContext'
import routes from '#globals/routes'

const getSuccessMessage = profile => (
  <Stack
    spacing={10}
    className='d-flex flex-column align-items-center'
    css={spacer.mrB30}
  >
    <Text color={colors.black}>
      Subject Added
      <br />
      <br />
      Access {profile.name}&apos;s profile (
      <img
        src={profile.icon.icon}
        width='24'
        alt={profile.name}
        style={{ verticalAlign: 'middle' }}
      />
      ) by clicking on their profile icon in the left sidebar or{' '}
      <Link
        borderColor={colors.black}
        to={routes.overviewDashboard.getPathFromParams({
          profileId: profile.id,
        })}
        color={colors.black}
      >
        click here to view {profile.name}&apos;s profile
      </Link>
    </Text>
  </Stack>
)

function AddSubjectForm({
  existingProfiles,
  profileIcons,
  products,
  isFormVisible,
  setIsFormVisible,
}) {
  return (
    <div className='container-md'>
      <div css={styles.container}>
        <header css={styles.header({ isFormVisible })}>
          <Button
            css={styles.btn}
            className='d-flex align-items-center justify-content-start'
            onClick={() => setIsFormVisible(s => !s)}
          >
            <img
              src={plus}
              alt='plus'
              css={styles.img({ isFormVisible })}
            />
            <Text color={colors.black} size='large'>
              Add a subject
            </Text>
          </Button>
        </header>
        {isFormVisible && (
          <Form
            existingProfiles={existingProfiles}
            profileIcons={profileIcons}
            products={products}
            setIsFormVisible={setIsFormVisible}
          />
        )}
      </div>
    </div>
  )
}

const Form = ({
  existingProfiles,
  profileIcons,
  products,
  setIsFormVisible,
}) => {
  const [error, setError] = useState()
  const [isAssigningToNewUser, setIsAssigningToNewUser] = useState(
    existingProfiles.length === 0,
  )
  const [showSavedCards, setShowSavedCards] = useState(false)
  const [showPaymentStep, setShowPaymentStep] = useState(true)
  const {
    showErrorModal,
    showSuccessModal,
  } = useSuccessOrErrorModalContext()
  const { notificationsDispatch } = useNotificationContext()

  const { cardsLoading, cards, cardsDispatch } = useCards()
  const { createProfile } = useCreateProfile()
  const { createSubscription } = useCreateSubscription()
  const { createNewPaymentMethod } = useCreateNewPaymentMethod()
  const [stripeSrc, setStripeSrc] = useState()
  const showPaymentInformationNotification = () =>
    notificationsDispatch({
      type: notificationActionTypes.SHOW_NOTIFICATION,
      payload: notificationTypes.PAYMENT_INFORMATION,
    })

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    setFieldError,
    isSubmitting,
    isValid,
  } = useFormik({
    // eslint-disable-next-line
    validate: async formData => {
      try {
        await validationSchema.validate(formData, {
          abortEarly: false,
          context: {
            showSavedCards,
            isAssigningToNewUser,
            isFreeSubscription:
              formData.subscription === subscriptionTypes.FREE,
          },
        })
      } catch (error) {
        return yupToFormErrors(error)
      }
    },

    initialValues: {
      isCardValid: false,
      subject: undefined,
      user: undefined,
      userIcon: undefined,
      searchUserName: undefined,
      subscription: subscriptionTypes.YEARLY,
      payment_method_id: undefined,
    },
    onSubmit: async formData => {
      const subcriptionVariables = {
        profileId: '',
        payment_method_id: '',
        productId: formData.subject.value,
        type: formData.subscription,
      }

      if (isAssigningToNewUser) {
        const { data } = await createProfile({
          variables: {
            icon_id: formData.userIcon,
            name: formData.searchUserName,
          },
        })
        const { id, name } = get(data, 'createProfile', {})
        subcriptionVariables.profileId = id
        setIsAssigningToNewUser(false)
        setFieldValue('user', {
          value: id,
          label: name,
        })
      } else {
        subcriptionVariables.profileId = formData.user.value
      }

      if (formData.subscription === subscriptionTypes.FREE) {
        try {
          const { data } = await createSubscription({
            variables: subcriptionVariables,
          })
          if (data.createSubscription) {
            showSuccessModal(
              getSuccessMessage(data.createSubscription.profile),
            )
            setIsFormVisible(false)
          }
        } catch (error) {
          const message = getErrorMessage(error)
          if (message) setError(message)
        }
      } else {
        // if user is in showSavedCards view, that means they chose a saved card
        if (showSavedCards) {
          subcriptionVariables.payment_method_id =
            formData.payment_method_id
        } else {
          try {
            subcriptionVariables.payment_method_id = await createNewPaymentMethod()
            cardsDispatch({ type: 'FETCH_CARDS' })
          } catch (error) {
            setFieldError('isCardValid', error.message)
            return
          }
        }

        try {
          const { data } = await createSubscription({
            variables: subcriptionVariables,
          })

          const stripeNextActionUrl = getStripeNextActionUrl(
            data.createSubscription,
          )

          if (stripeNextActionUrl) {
            setStripeSrc(stripeNextActionUrl)
          } else {
            showSuccessModal(
              data.createSubscription.profile
                ? getSuccessMessage(data.createSubscription.profile)
                : 'Subject Added',
            )
            setIsFormVisible(false)
            showPaymentInformationNotification()
          }
        } catch (error) {
          const message = getErrorMessage(error)
          if (message) {
            showErrorModal(message)
            setIsFormVisible(false)
          }
        }
      }
      await refetchUser()
      await refetchProfile(subcriptionVariables.profileId)
    },
  })

  const handleCardChange = data => {
    if (data.error) {
      return setFieldError('isCardValid', data.error.message)
    }
    return setFieldValue('isCardValid', data.complete)
  }

  useEffect(() => {
    if (isAssigningToNewUser) {
      setFieldValue('userIcon', profileIcons[0].id)
    }
  }, [isAssigningToNewUser, profileIcons, setFieldValue])

  useEffect(() => {
    if (isAssigningToNewUser) {
      setFieldValue('user', undefined)
      setFieldTouched('user', false)
    } else {
      setFieldValue('searchUserName', '')
      setFieldValue('userIcon', '')
      setFieldTouched('searchUserName', false)
      setFieldTouched('userIcon', false)
    }
  }, [isAssigningToNewUser, setFieldTouched, setFieldValue])

  useEffect(() => {
    if (values.subscription === subscriptionTypes.FREE) {
      setShowPaymentStep(false)
    } else {
      setShowPaymentStep(true)
    }
  }, [values.subscription])

  useEffect(() => {
    setError('')
  }, [values])

  useEffect(() => {
    const { id } = find(cards, 'default') || {}
    if (id) {
      setFieldValue('payment_method_id', id)
      setShowSavedCards(true)
    }
  }, [cards, setFieldValue])
  return (
    <>
      <form onSubmit={handleSubmit} css={styles.form}>
        <div className='row'>
          <div className='col-xl-3 col-lg-4 d-flex align-items-start'>
            <NumberInCircle number='1' css={styles.numberInCircle} />
            <Text weight='bold' size='large' color={colors.grey}>
              Subject
            </Text>
          </div>
          <div className='offset-xl-2 col-xl-7 col-lg-8'>
            <Select
              label='Select subject'
              options={products}
              color={colors.black}
              name='subject'
              onChange={data => setFieldValue('subject', data)}
              onBlur={() => setFieldTouched('subject', true)}
              value={values.subject}
              error={touched.subject && errors.subject}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-xl-3 col-lg-4 d-flex align-items-start'>
            <NumberInCircle number='2' css={styles.numberInCircle} />
            <Text weight='bold' size='large' color={colors.grey}>
              User
            </Text>
          </div>
          <div className='offset-xl-2 col-xl-7 col-lg-8'>
            {isAssigningToNewUser ? (
              <>
                <TextField
                  label='Assign to a new user'
                  placeholder='eg. John'
                  name='searchUserName'
                  onChange={handleChange}
                  onBlur={() =>
                    setFieldTouched('searchUserName', true)
                  }
                  value={values.searchUserName}
                  error={
                    touched.searchUserName && errors.searchUserName
                  }
                  autoComplete='off'
                />
                <div
                  className='d-flex flex-column'
                  css={spacer.mrT40}
                >
                  <Text color={colors.black} css={spacer.mrB10}>
                    Choose user&apos;s icon
                  </Text>
                  <div
                    className='d-flex flex-wrap'
                    style={{ marginBottom: -10, marginRight: -20 }}
                  >
                    {profileIcons.map(({ icon, id }) => (
                      <UserIconSelect
                        key={id}
                        iconUrl={icon}
                        value={id}
                        name='userIcon'
                        onChange={handleChange}
                        onBlur={() =>
                          setFieldTouched('userIcon', true)
                        }
                        css={[spacer.mrR20, spacer.mrB10]}
                        checked={values.userIcon === id}
                      />
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <Select
                label='Assign to an existing user'
                options={existingProfiles.map(profile => ({
                  value: profile.id,
                  label: profile.name,
                }))}
                name='user'
                color={colors.black}
                css={spacer.mrB30}
                error={touched.user && errors.user}
                onChange={data => setFieldValue('user', data)}
                onBlur={() => setFieldTouched('user', true)}
                value={values.user}
              />
            )}

            {existingProfiles.length > 0 && (
              <>
                <div
                  className='d-flex justify-content-center'
                  css={spacer.mrB30}
                >
                  <Pill text='OR' type='muted' />
                </div>
                <Button
                  type='button'
                  underlineColor={colors.black}
                  onClick={() => setIsAssigningToNewUser(s => !s)}
                >
                  {isAssigningToNewUser
                    ? 'Assign to existing user'
                    : 'Assign to new user'}
                </Button>
              </>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-xl-3 col-lg-4 d-flex align-items-start'>
            <NumberInCircle number='3' css={styles.numberInCircle} />
            <Text weight='bold' size='large' color={colors.grey}>
              Subscription
            </Text>
          </div>
          <div className='offset-xl-2 col-xl-7 col-lg-8'>
            <Text color={colors.black} css={spacer.mrB10}>
              Choose a subscription type
            </Text>
            <div css={[spacer.mrB20, pricingBandsStyles]}>
              <OptionBar
                multi={false}
                css={spacer.mrB20}
                option={pricingTexts.yearly}
                name='subscription'
                value={subscriptionTypes.YEARLY}
                onChange={handleChange}
                checked={
                  values.subscription === subscriptionTypes.YEARLY
                }
              />
              <OptionBar
                multi={false}
                option={pricingTexts.monthly}
                name='subscription'
                css={spacer.mrB20}
                onChange={handleChange}
                value={subscriptionTypes.MONTHLY}
                checked={
                  values.subscription === subscriptionTypes.MONTHLY
                }
              />
              <OptionBar
                multi={false}
                option={pricingTexts.free}
                name='subscription'
                css={spacer.mrB20}
                onChange={handleChange}
                value={subscriptionTypes.FREE}
                checked={
                  values.subscription === subscriptionTypes.FREE
                }
              />
            </div>
          </div>
        </div>
        {showPaymentStep && (
          <div className='row'>
            <div className='col-xl-3 col-lg-4 d-flex align-items-start'>
              <NumberInCircle
                number='4'
                css={styles.numberInCircle}
              />
              <Text weight='bold' size='large' color={colors.grey}>
                Payment
              </Text>
            </div>
            {cardsLoading ? (
              <div className='offset-xl-2 col-xl-7 col-lg-8'>
                <Loader isRelative height={150} />
              </div>
            ) : (
              <Stack
                spacing={20}
                className='offset-xl-2 col-xl-7 col-lg-8'
              >
                {cards.length > 0 && (
                  <Button
                    type='button'
                    underlineColor={colors.black}
                    onClick={() => setShowSavedCards(s => !s)}
                  >
                    {showSavedCards
                      ? 'Use new card'
                      : 'Use saved card'}
                  </Button>
                )}
                {showSavedCards ? (
                  cards.map(card => (
                    <label
                      key={card.id}
                      htmlFor={card.id}
                      className='d-flex align-items-center'
                    >
                      <Checkbox
                        id={card.id}
                        isRadio
                        onChange={handleChange}
                        value={card.id}
                        checked={values.payment_method_id === card.id}
                        name='payment_method_id'
                        css={styles.checkbox}
                      />
                      <div className='flex-grow-1'>
                        <CreditCard
                          {...card}
                          showActions={false}
                          css={styles.creditCard({
                            isSelected:
                              values.payment_method_id === card.id,
                          })}
                        />
                      </div>
                    </label>
                  ))
                ) : (
                  <div>
                    <StripeCard
                      onChange={handleCardChange}
                      onBlur={() =>
                        setFieldTouched('isCardValid', true)
                      }
                      error={
                        touched.isCardValid && errors.isCardValid
                      }
                    />
                  </div>
                )}
              </Stack>
            )}
          </div>
        )}
        {error && (
          <div className='row'>
            <div className='col-xl-3 col-lg-4' />
            <div className='offset-xl-2 col-xl-7 col-lg-8'>
              <div className='d-flex align-items-center flex-wrap'>
                <ErrorText>{error}</ErrorText>
              </div>
            </div>
          </div>
        )}
        <div className='row'>
          <div className='col-xl-3 col-lg-4' />
          <div className='offset-xl-2 col-xl-7 col-lg-8'>
            <div className='d-flex align-items-center flex-wrap'>
              <Button
                size='medium'
                variant='black'
                css={spacer.mrR20}
                disabled={!isValid}
                isLoading={isSubmitting}
                type='submit'
              >
                ADD SUBJECT
              </Button>
              <Button
                type='button'
                onClick={() => setIsFormVisible(false)}
                underlineColor={colors.black}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </form>
      {isSubmitting && <Overlay />}
      {stripeSrc && (
        <Stripe3DSModal
          iframeSrc={stripeSrc}
          onSuccess={async message => {
            await refetchUser()
            refetchProfile(values.user.value)
            showSuccessModal(message)
            setStripeSrc('')
            setIsFormVisible(false)
            showPaymentInformationNotification()
          }}
          onError={async message => {
            await refetchUser()
            refetchProfile(values.user.value)
            showErrorModal(message)
            setStripeSrc('')
            setIsFormVisible(false)
          }}
        />
      )}
    </>
  )
}

const commonProps = {
  profileIcons: PropTypes.array.isRequired,
  existingProfiles: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  setIsFormVisible: PropTypes.func.isRequired,
}

AddSubjectForm.propTypes = {
  ...commonProps,
  isFormVisible: PropTypes.bool.isRequired,
}
Form.propTypes = commonProps

export default AddSubjectForm
