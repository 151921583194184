import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import { GET_USER_QUERY } from './getUser'

export const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation cancelSubscription(
    $subscription_id: ID!
    $profile_id: ID!
    $cancel: Boolean!
    $cancel_confirmation: Boolean!
  ) {
    cancelSubscription(
      input: {
        subscription_id: $subscription_id
        profile_id: $profile_id
        cancel: $cancel
        cancel_confirmation: $cancel_confirmation
      }
    ) {
      id
      stripe_id
    }
  }
`
export const useCancelSubscription = () => {
  const [cancelSubscription, { data, loading, error }] = useMutation(
    CANCEL_SUBSCRIPTION_MUTATION,
    {
      refetchQueries: [{ query: GET_USER_QUERY }],
      awaitRefetchQueries: true,
    },
  )

  return { cancelSubscription, data, loading, error }
}
