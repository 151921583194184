import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Title from '@dqp/common/components/Title/Title'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import Text from '@dqp/common/components/Text/Text'
import TextField from '@dqp/common/components/TextField/TextField'
import ModalContainer from '#components/ModalContainer/ModalContainer'
import UserIconSelect from '#components/UserIconSelect/UserIconSelect'
import Stack from '#components/Stack/Stack'

function UpdateUserModal({
  isOpen,
  closeModal,
  userName,
  userIcon,
  profileIcons,
  isLoading,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}) {
  const [name, setName] = useState(userName || '')
  const [iconId, setIconId] = useState(userIcon || '')

  return (
    <ModalContainer isOpen={isOpen} closeModal={closeModal}>
      <Stack className='d-flex flex-column text-left' spacing={30}>
        <Title color={colors.black} size='xLarge'>
          User
        </Title>
        <div>
          <TextField
            label='Update user'
            color={colors.black}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div>
          <Text color={colors.black} css={spacer.mrB10}>
            Choose user&apos;s icon
          </Text>
          <div className='d-flex'>
            {profileIcons.map(({ icon, id }) => (
              <UserIconSelect
                key={id}
                iconUrl={icon}
                value={id}
                name='userIcon'
                onChange={e => setIconId(e.target.value)}
                css={spacer.mrR20}
                checked={iconId === id}
              />
            ))}
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <Button
            size='small'
            variant='blue'
            css={spacer.mrR20}
            onClick={() => onPrimaryButtonClick({ name, iconId })}
            isLoading={isLoading}
          >
            SAVE
          </Button>
          <Button
            onClick={onSecondaryButtonClick}
            underlineColor={colors.black}
          >
            Cancel
          </Button>
        </div>
      </Stack>
    </ModalContainer>
  )
}

UpdateUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  userIcon: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onPrimaryButtonClick: PropTypes.func.isRequired,
  onSecondaryButtonClick: PropTypes.func.isRequired,
  profileIcons: PropTypes.array.isRequired,
}

export default UpdateUserModal
