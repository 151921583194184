import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

const GET_PRODUCTS_QUERY = gql`
  query getProducts {
    getProducts(first: 100, page: 1) {
      data {
        id
        value: id
        label: name
      }
    }
  }
`

export const useGetProducts = () => {
  const { data: products, loading, error } = useQuery(
    GET_PRODUCTS_QUERY,
  )

  return { products, loading, error }
}
