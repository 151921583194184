import React from 'react'
import PropTypes from 'prop-types'
import Text from '@dqp/common/components/Text/Text'
import { colors } from '@dqp/common/styles/utilities'
import { useMutation } from '@apollo/react-hooks'
import styles from './styles'
import { CANCEL_SUBSCRIPTION_MUTATION } from '#api/cancelSubscription'
import CancelSubscriptionModal from '#components/Modals/CancelSubscriptionModal'
import useModal from '#utils/useModal'
import dateToDDMMYYYY from '#utils/dateToDDMMYYYY'
import { GET_USER_QUERY } from '#api/getUser'
import { useSuccessOrErrorModalContext } from '#context/SuccessOrErrorModalContext'
import getErrorMessage from '#utils/getErrorMessage'

const CancelSubscriptionTooltipItem = ({ subscription }) => {
  const { isOpen, openModal, closeModal } = useModal()
  const {
    showSuccessModal,
    showErrorModal,
  } = useSuccessOrErrorModalContext()
  const [cancelSubscription, { loading }] = useMutation(
    CANCEL_SUBSCRIPTION_MUTATION,
    {
      onCompleted: response => {
        if (response.cancelSubscription) {
          showSuccessModal('Subscription cancelled')
          closeModal()
        }
      },
      onError: error => {
        const message = getErrorMessage(error)
        if (message) {
          showErrorModal(message)
          closeModal()
        }
      },
      refetchQueries: [{ query: GET_USER_QUERY }],
      awaitRefetchQueries: true,
    },
  )
  return (
    <>
      <Text
        size='small'
        color={colors.black}
        weight='bold'
        onClick={openModal}
        css={styles.text}
      >
        Cancel Subscription
      </Text>
      {isOpen && (
        <CancelSubscriptionModal
          isOpen={isOpen}
          closeModal={closeModal}
          onSecondaryButtonClick={closeModal}
          subjectName={subscription.product.name}
          userName={subscription.profile.name}
          renewalDate={dateToDDMMYYYY(
            subscription.current_period_end,
          )}
          isLoading={loading}
          onPrimaryButtonClick={() => {
            cancelSubscription({
              variables: {
                subscription_id: subscription.id,
                profile_id: subscription.profile.id,
                cancel: true,
                cancel_confirmation: true,
              },
            })
          }}
        />
      )}
    </>
  )
}

CancelSubscriptionTooltipItem.propTypes = {
  subscription: PropTypes.object.isRequired,
}

export default CancelSubscriptionTooltipItem
