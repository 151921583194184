import React from 'react'
import PropTypes from 'prop-types'
import Text from '@dqp/common/components/Text/Text'
import { colors } from '@dqp/common/styles/utilities'
import { get } from 'lodash-es'
import styles from './styles'
import useModal from '#utils/useModal'
import { GET_USER_QUERY } from '#api/getUser'
import { useChangeSubscriptionProfile } from '#api/changeSubscriptionProfile'
import { useGetProfiles } from '#api/getProfiles'
import ChangeProfileModal from '#components/Modals/ChangeProfileModal'
import getErrorMessage from '#utils/getErrorMessage'
import { useSuccessOrErrorModalContext } from '#context/SuccessOrErrorModalContext'
import { useGetProfileIcons } from '#api/getProfileIcons'

const ChangeProfileTooltipItem = ({ subscription }) => {
  const { isOpen, openModal, closeModal } = useModal()
  const {
    showErrorModal,
    showSuccessModal,
  } = useSuccessOrErrorModalContext()
  const { profiles } = useGetProfiles()
  const { profileIcons } = useGetProfileIcons()
  const { changeSubscriptionProfile } = useChangeSubscriptionProfile({
    onCompleted: response => {
      const message = get(
        response,
        'changeSubscriptionProfile.message',
      )
      if (message) showSuccessModal(message)
      closeModal()
    },
    onError: error => {
      const message = getErrorMessage(error)
      if (message) showErrorModal(message)
      else showErrorModal(error.message)
      closeModal()
    },
    refetchQueries: [{ query: GET_USER_QUERY }],
    awaitRefetchQueries: true,
  })
  return (
    <>
      <Text
        size='small'
        color={colors.black}
        weight='bold'
        onClick={openModal}
        css={styles.text}
      >
        Change User
      </Text>
      {isOpen && (
        <ChangeProfileModal
          isOpen={isOpen}
          closeModal={closeModal}
          profileIcons={profileIcons.getProfileIcons || []}
          profiles={profiles.getProfiles || []}
          currentProfile={{
            value: subscription.profile.id,
            label: subscription.profile.name,
          }}
          onPrimaryButtonClick={async newProfileId => {
            await changeSubscriptionProfile({
              variables: {
                subscription_id: subscription.id,
                profile_id: subscription.profile.id,
                new_profile_id: newProfileId,
              },
            })
          }}
        />
      )}
    </>
  )
}

ChangeProfileTooltipItem.propTypes = {
  subscription: PropTypes.object.isRequired,
}

export default ChangeProfileTooltipItem
