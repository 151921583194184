import React from 'react'
import PropTypes from 'prop-types'
import Button from '@dqp/common/components/Button/Button'
import { useResumeSubscription } from '#api/restartSubscription'
import getErrorMessage from '#utils/getErrorMessage'
import { useSuccessOrErrorModalContext } from '#context/SuccessOrErrorModalContext'
import { GET_USER_QUERY } from '#api/getUser'

const RestartSubscription = ({ subscription }) => {
  const {
    showErrorModal,
    showSuccessModal,
  } = useSuccessOrErrorModalContext()
  const { resumeSubscription, loading } = useResumeSubscription({
    onCompleted: response => {
      if (response.resumeSubscription) {
        showSuccessModal('Subscription resumed.')
      }
    },
    onError: error => {
      const message = getErrorMessage(error)
      if (message) showErrorModal(message)
    },
    refetchQueries: [{ query: GET_USER_QUERY }],
  })
  return (
    <Button
      variant='blue'
      size='small'
      isLoading={loading}
      onClick={() =>
        resumeSubscription({
          variables: {
            subscription_id: subscription.id,
            profile_id: subscription.profile.id,
          },
        })
      }
    >
      Renew
    </Button>
  )
}

RestartSubscription.propTypes = {
  subscription: PropTypes.object.isRequired,
}

export default RestartSubscription
